<template>
    <div>
        <router-view />
    </div>
</template>

<script>
export default {
    name: "Forms-Preview"
}
</script>

<style lang="scss" scoped></style>